<template>
  <div class="map-widget">
    <div class="map" v-if="mapClientString">
      <pre><mud-text :v="mapClientString.output"/></pre>
    </div>
  </div>
</template>

<script>
import { strip } from '@webmuds/colors'
import { diffChars } from 'diff'

export default {
  name: 'map-widget',
  props: ['map-client-string'],
  mounted () {
    setTimeout(() => {
      this.scrollH()
      this.scrollV()
    }, 500)
  },
  methods: {
    scrollH () {
      var width = this.width
      if (width === 0) return

      var el = this.$el
      var ratio = this.column / width

      el.scrollLeft = (el.scrollWidth * ratio) - (el.clientWidth / 2)
    },
    scrollV () {
      var height = this.height
      if (height === 0) return

      var el = this.$el
      var ratio = this.line / height

      el.scrollTop = (el.scrollHeight * ratio) - (el.clientHeight / 2)
    }
  },
  computed: {
    marker () {
      return this.mapClientString?.$marker
    },

    // The empty map, without marks and color tags.
    empty () {
      var marker = this.marker
      if (!marker) return ''

      var oldMarkId = marker.markId
      marker.markId = false
      var result = marker.render()
      marker.markId = oldMarkId
      return strip(result)
    },

    // The marked map, without color tags.
    marked () {
      var string = this.mapClientString
      if (!string) return ''

      return strip(string.output)
    },

    // An array of lines of the empty map.
    lines () {
      return this.empty.split('\n')
    },

    // An array of lines of the marked map.
    markedLines () {
      return this.marked.split('\n')
    },

    // The width of the map, in characters.
    width () {
      var maxWidth = 0
      for (var line of this.lines) {
        if (line.length > maxWidth) maxWidth = line.length
      }
      return maxWidth
    },

    // The height of the map, in characters.
    height () {
      return this.lines.length
    },

    // Returns the median line where the current marker is placed.
    line () {
      var minLine = null
      var maxLine = null

      var lines = this.lines
      var markedLines = this.markedLines
      var linesLength = lines.length
      var emptyLine, markedLine

      for (var l = 0; l < linesLength; l++) {
        emptyLine = lines[l]
        markedLine = markedLines[l]

        if (emptyLine === markedLine) continue

        // A diff has been found.
        // Marks with the same ID can repeat in multiple places,
        // so we need to work with a range.
        if (minLine == null) minLine = l + 1
        if (maxLine == null) maxLine = minLine
        if (maxLine < l) maxLine = l
      }

      // No diffs at all.
      if (minLine == null || maxLine == null) return 0

      // We get the first and last line where diffs have appeared,
      // and return the average value.
      return Math.floor((minLine + maxLine) / 2)
    },

    // Returns the median column where the current marker is placed.
    column () {
      var minColumn = null
      var maxColumn = null

      var lines = this.lines
      var markedLines = this.markedLines
      var linesLength = lines.length
      var emptyLine, markedLine
      var part, diffs, lineBuf

      for (var l = 0; l < linesLength; l++) {
        emptyLine = lines[l]
        markedLine = markedLines[l]

        if (emptyLine === markedLine) continue

        lineBuf = 0

        diffs = diffChars(emptyLine, markedLine)

        for (part of diffs) {
          if (part.removed) continue // Ignore removed parts

          if (part.added) {
            if (minColumn == null) minColumn = lineBuf
            if (maxColumn == null) maxColumn = lineBuf
            maxColumn += part.count
            lineBuf += part.count
            continue
          }

          // Unmodified part.
          lineBuf += part.count
          if (minColumn == null) minColumn = lineBuf
          if (maxColumn == null) maxColumn = minColumn
        }
      }

      // No diffs at all.
      if (minColumn == null || maxColumn == null) return 0

      // We get the first and last column where diffs have appeared,
      // and return the average value.
      return Math.floor((minColumn + maxColumn) / 2)
    }
  },
  watch: {
    line (v) {
      if (v === 0) return
      this.$nextTick(this.scrollV)
    },
    column (v) {
      if (v === 0) return
      this.$nextTick(this.scrollH)
    }
  },
  components: {
    MudText: () => import('@/components/MudText.vue')
  }
}
</script>

<style lang="scss">
.map-widget {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 200px;
  height: 200px;
  padding: 8px;
  border: 1px solid gray;
  border-radius: 4px;
  background-color: rgba(20, 20, 20, 0.75);
  overflow-x: scroll;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
  }

  .map {
    position: relative;
    margin: auto;
    display: table;
  }

  // .title {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  // }

  pre {
    line-height: 1.2;
  }
}
</style>
